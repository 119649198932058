import React from 'react';
import { PiNotebookFill } from "react-icons/pi";
import { FiPhoneCall } from "react-icons/fi";
import {AspectRatio} from '@chakra-ui/react'


const Contact = () => {
  return (
    <div className='text-white mt-8'>
         <div className='flex items-center gap-4'>
            {/* <TfiWrite /> */}
            <div>
                <h1 className='font-bold text-md uppercase text-xl'>Холбоо барих</h1>
                <p>Contact</p>
            </div>
        </div>

        <div className='mt-14'>
            <div className='flex justify-center'>
              <FiPhoneCall size={40}/>
            </div>
            <h1 className='text-center uppercase text-sm mt-4 font-bold'>Сансар салбар хаяг байршил :</h1>
            <p className='text-sm text-gray-300 text-center mt-4'>Баянзүрх дүүрэг 3р хороо Сансар  33р сургуулийн урд Эрдэнийн 108 хотхон Урдаасаа ирэх бол 13 автобусны буудлын баруун талд Zet centeriin баруун талаар дээшээ өгсөөд Эрдэнийн 108 хотхоны 1давхарт замруу харсан шилэн хаалга</p>
            <p className='text-center uppercase font-bold text-sm mt-4'>Оффисс утас: 79790809</p>
            <p className='text-center uppercase font-bold text-sm mt-2 mb-4'>Хүргэлт утас: 96808989</p>

            <div className='mt-4'>
              <AspectRatio ratio={16 / 18}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2673.787113915572!2d106.9396587!3d47.92115649999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d9693e78cc4bfa5%3A0x9a5af8df1a4b180a!2sNuMi%20hair%20salon!5e0!3m2!1sen!2smn!4v1724334160664!5m2!1sen!2smn" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </AspectRatio>
            </div>

            <h1 className='text-center uppercase text-sm mt-12 font-bold'>10-р хороолол салбар хаяг байршил :</h1>
            <p className='text-sm text-gray-300 text-center mt-4'>БГД 10-р хорооллын замын урд талд Төв зам дагуу БГД-ийн 28-р хорооны байрны баруун талд 50-р байрны Б1 давхар</p>
            <p className='text-center uppercase font-bold text-sm mt-4'>Оффисс утас: 79790809</p>
            <p className='text-center uppercase font-bold text-sm mt-2 mb-4'>Хүргэлт утас: 96808989</p>

            <div className='mt-4'>
              <AspectRatio ratio={16 / 18}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2674.199756954495!2d106.86553448888546!3d47.91317220179736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d9693370de51c9d%3A0x9fbb4e5fb38615d1!2zMTAwMC3QvSDRiNC-0YDQu9C-0LM!5e0!3m2!1sen!2smn!4v1731847470883!5m2!1sen!2smn" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </AspectRatio>
            </div>
        </div>
    </div>
  )
}

export default Contact