import React from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
  } from '@chakra-ui/react';
import { TfiWrite } from 'react-icons/tfi';
import { PiNotebookFill } from "react-icons/pi";


const Service = () => {
  return (
    <div className='text-white mt-8'>
         <div className='flex items-center gap-4'>
            {/* <TfiWrite /> */}
            <div>
                <h1 className='font-bold text-md uppercase text-xl'>Үйлчилгээний нөхцөл</h1>
                <p>Terms and Condition</p>
            </div>
        </div>
        <div className='mt-6 grid justify-center text-center'>
        <div className='flex justify-center'>
            <PiNotebookFill size={40}/>
        </div>
        <p className='px-6 text-gray-300 text-sm mt-2'>Хэрэглэгч нь Saincargo.mn веб сайтад хандсан үеэс эхлэн Үйлчилгээны нөхцөлыг мөрдөгдөх бөгөөд тухайн үеэс уг нөхцөлийг хүлээн зөвшөөрсөнд тооцно.</p>
        </div>

        <div className='text-white mt-6'>
            <Accordion allowToggle>
                <AccordionItem className='border rounded-2xl bg-[#4343af]'>
                    <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign='left'>
                                Үйлчилгээний нөхцөл
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} mx={4} className='text-sm text-gray-300'>
                        <p className='my-1'>1.1 Энэхүү журмаар Сайн карго-ийн (цаашид “Карго” гэнэ) захиалгат худалдааны үйлчилгээ, түүнтэй холбоотой үүсэх бүх төрлийн харилцааг зохицуулна.</p>
                        <p className='my-3'>1.2 Карго нь БНХАУ-д байрлалтай онлайн болон онлайн бус дэлгүүр, нийлүүлэгчээс хэрэглэгчийн сонгон захиалсан бараа бүтээгдэхүүнийг худалдан авч тээвэрлэх, нийлүүлэх үйлчилгээ үзүүлнэ.</p>
                        <p className='my-3'>1.3 Энэхүү журмын хэрэгжилтэд КАРГО болон үйлчлүүлэгч хамтран хяналт тавина.</p>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem className='border rounded-2xl bg-[#4343af] mt-2'>
                    <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign='left'>
                                Хэрэглэгчийн бүртгэл
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} mx={4} className='text-sm text-gray-300'>
                        <p className='mt-1'>2.1 Saincargo.mn сайтын үйлчилгээг ашиглахын тулд олон нийтийн сүлжээний нэвтрэх эрхийг ашиглан хэрэглэгчээр бүртгүүлж, хүчин төгөлдөр утасны дугаарыг оруулах шаардлагатай.</p>
                        <p className='mt-3'>2.2 Та өөрийн бүртгэл, нууц үгийн аюулгүй байдлыг хангах үүрэгтэй бөгөөд таны бүртгэлээсхийгдсэн аливаа үйлдлийг хэрэглэгч өөрөө бүрэн хариуцна.</p>
                        <p className='mt-3'></p>
                        <p className='mt-3'></p>
                        <p className='mt-3'></p>
                        <p className='mt-3'></p>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem className='border rounded-2xl bg-[#4343af] mt-2'>
                    <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign='left'>
                                Линк захиалга
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} mx={4} className='text-sm text-gray-300'>
                        <p className='mt-1'>3.1 КАРГО нь БНХАУ үйл ажиллагаа эрхэлдэг онлайн болон онлайн бус дэлгүүрүүд, нийлүүлэгчдээс хэрэглэгчийн сонгон захиалсан бараа бүтээгдэхүүнийг худалдан авч, тээвэрлэн үйлчилнэ.</p>
                        <p className='mt-3'>3.2 Линкийн захиалгын үйлчилгээ нь “Сайн карго- таобао захиалга, тээвэр үйлчилгээ” нэртэй албан ёсны фэйсбүүк пэйж хуудсаар захиалга өгснөөс эхлэх ба  барааг хүлээлгэн өгөх хүртэлх бусад үйл явц энэ системээр удирдагдана.</p>
                        <p className='mt-3'>3.3 Захиалгын үнийг хэрэглэгч зөвшөөрөн төлбөр хийгдсэнээс хойш 24 цагийн дотор барааны захиалга хийгдэнэ.</p>
                        <p className='mt-3'>3.4 Бид захиалга хүлээж авахаас хойш захиалга бүрийн барааны үнийг баталгаажуулан ярих, лавлах холбоо тогтоох боломжгүй. Захиалах гэж буй барааны төлбөр нь таны төлсөн дүнгээс өндөр байвал захиалахаас өмнө бид тантай холбогдож асуудлыг шийдэх, эсвэл захиалгыг цуцалж танд мэдэгдэх болно.</p>
                        <p className='mt-3'>3.5 Захилагч захиалга хийгдэх хугацаа хэтэрсэн бөгөөд танд ямар нэгэн мэдээлэл очоогүй бол заавал эргэн холбогдож захилгаа шалгуулах. Гүйлгээний утга зөв бичсэн эсэхийг тодруулах</p>
                        <p className='mt-3'>3.6 Хэрэглэгч бараа захиалгын үнийн 100 хувийг төлснөөр захиалгыг баталгаажсан гэж үзэн худалдан авалтыг тус журмын 3.3-т заасан хугацаанд хийнэ.</p>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem className='border rounded-2xl bg-[#4343af] mt-2'>
                    <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign='left'>
                            ҮНЭ
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} mx={4} className='text-sm text-gray-300'>
                        <p className='mt-1'>4.1 Барааны үнийг Карго нь бараа УБ хотод ирж, бүртгэлд орох явцад тогтооно.</p>
                        <p className='mt-3'>4.2 Захиалгын үнэд Хятадаас Улаанбаатар хүртэлх тээврийн зардал ороогүй бөгөөд Улаанбаатарт бараа ирсний дараагаар бодит жин болон бодит овор хэмжээгээр тооцож тээврийн зардлыг захиалагчаас нэхэмжилнэ.</p>
                        <p>Үүнд: БНХАУ-д</p>
                        <p>• Барааны үнэ</p>
                        <p>• Тухайн барааны ЭРЭЭН хот хүртэлх хүргэлтийн үнэ /Линкийн захиалга өгсөн бол бодолт хийх явцад Эрээн хот хүртгэлх зардлыг тооцсон байдаг, зарим нэр төрлийн оцнгой бараа нэмэлт төлбөр бодогддог/</p>
                        <p>Монгол улсад:</p>
                        <p>• БНХАУ-аас Монгол хүртэлх тээврийн зардал</p>
                        <p>• Монгол улсын гаалийн татвар (Монгол улсын гаалийн татварын дагуу)</p>
                        <p className='mt-3'>4.3 Хэрэглэгч КАРГО-н профайл хэсгээс үнийн бодолтын дэлгэрэнгүйгээр харах боломжтой.</p>
                        <p className='mt-3'>4.4 Бараа тус бүрийн уут, сав, баглаа, боодол бүрт үнэ бодогдох бөгөөд нэг захиалагчийн барааг нийлүүлж үнэ тооцох үйлчилгээ байхгүй.  </p>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem className='border rounded-2xl bg-[#4343af] mt-2'>
                    <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign='left'>
                            Тээвэрлэлт
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} mx={4} className='text-sm text-gray-300'>
                        <p className='mt-1'>5.1 Тээвэрлэлт нь БНХАУ дахь хүргэлт, БНХАУ-аас Монгол хүртэлх тээвэрлэлт гэсэн 2 үндсэн үе шатаас бүрдэнэ.</p>
                        <p className='mt-3'>5.2 БНХАУ дахь хүргэлт нь тухайн дэлгүүр, нийлүүлэгчийн ашиглаж буй дотоодын хүргэлтийн үйлчилгээгээр нийлүүлэгч хүргэлтийг бүрэн хариуцна.</p>
                        <p className='mt-3'>5.3 Бараа бүтээгдэхүүн КАРГО-ны Эрээн дэх агуулахад хүргэгдэн, Монгол улсруу ачигдсанаас хойш хэвийн ажиллагаатай үед дунджаар 2-3 хоног дотор УБ хотод ирнэ.  
                        Бүх нийтийн баяр ёслол, гэнэтийн давагдашгүй хүчин зүйл үүссэн тохиолдолд дээрх хугацаа сунгагдаж болно. Гэнэтийн болон давагдашгүй хүчин зүйл гэдэгт гал түймэр, газар хөдлөлт, салхи шуурга, аянга цахилгаан,
                         үер усны гамшиг, нийтийг хамарсан үймээн самуун, эмх замбараагүй байдал, ажил хаялт,
                         эрчим хүчний тасалдал, эсэргүүцлийн хөдөлгөөн, гоц халдварт өвчин, дайн, террорист халдлага, төрийн эрх бүхий байгууллагын шийдвэр, хорио, цээр зэрэг талуудын хүсэл зоригоос үл хамаарах шалтгаанууд багтана.</p>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem className='border rounded-2xl bg-[#4343af] mt-2'>
                    <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign='left'>
                            ТЭЭВРИЙН ЗАРДАЛ
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} mx={4} className='text-sm text-gray-300'>
                        <p className='mt-1'>6.1 Барааны тээврийн зардлыг Овор хэмжээ ихтэй /чихмэл тоглоом, хивсэнцэр, дугуй, зурагт, буйдан, хөргөгч гэх мэт/ барааны тээврийн зардлыг овор хэмжээгээр нь м.кубээр бодно.</p>
                        <p className='mt-3'>6.2 Овор хэмжээ жижиг боловч жин ихтэй ачааг бүрэн болон бүрэн бус кг-ын үнээр мөн, барилгын хүнд материал, хүнд тоног төхөөрөмжийг жин тонны үнээр тооцно.</p>
                        <p className='mt-3'>6.3 Захиалгыг хэрэглэгчид олгох үед хэрэглэгч бараа бүтээгдэхүүнийг тухай бүрт шалган, эвдрэл гэмтлийг нягтлан үзсэний дараа хүлээн авна. Барааг хэрэглэгч хүлээн авснаас хойш гарсан эвдрэл гэмтэл болон бусад асуудлуудад КАРГО хариуцлага хүлээхгүй.</p>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem className='border rounded-2xl bg-[#4343af] mt-2'>
                    <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign='left'>
                            ХОРИОТОЙ БОЛОН ТУСГАЙ ЗӨВШӨӨРӨЛТЭЙ БАРАА
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} mx={4} className='text-sm text-gray-300'>
                        <p className='mt-1'>7.1 Гаалиар тээвэрлэхийг хориглодог бүтээгдэхүүний жагсаалтад орсон бүх төрлийн бараа бүтээгдэхүүний захиалга хийхгүй. Тухайлбал:</p>
                        <p>• Галт зэвсэг, байлдааны галт хэрэгсэл, тэсэрч дэлбэрэх болон цацраг идэвхт бодис, түргэн шатамхай бодис, орчиндоо аюул учруулж болох бусад хэрэгсэл;</p>
                        <p>• Хортон ургамал, амьтан;</p>
                        <p>• Зориулалтын савгүй шингэн будаг, будаг шингэлэгч</p>
                        <p>• Архи, согтууруулах ундааны зүйлс</p>
                        <p>• Хар тамхи, мансууруулах буюу хордуулах бодис;</p>
                        <p>• Электрон тамхи, асаагуур, шингэн хий;</p>
                        <p>• Хуульд өөрөөр заагаагүй бол Монгол Улсын болон гадаад улсын мөнгөн тэмдэгт;</p>
                        <p>• Түргэн муудах хүнсний бүтээгдэхүүн;</p>
                        <p>• Садар самууныг сурталчилсан хэвлэл, ном зохиол, зураг, кино, дүрс бичлэг, бусад материал;</p>
                        <p>• Хууль тогтоомжоор хориглосон бусад зүйл</p>
                        <p className='mt-3'>7.2 Тусгай зөвшөөрлийн лиценз шаарддаг бараа бүтээгдэхүүн захиалах тохиолдолд хэрэглэгч тухайн бүтээгдэхүүний тусгай зөвшөөрлийн лицензтэй бол КАРГО-аар Монгол улсын гааль хүртэлх гүйцэтгэлийг гүйцэтгүүлж болно.</p>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem className='border rounded-2xl bg-[#4343af] mt-2'>
                    <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign='left'>
                            ХҮРГЭЛТ
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} mx={4} className='text-sm text-gray-300'>
                        <p className='mt-1'>8.1 КАРГО хэрэглэгчийн хүсэлд нийцүүлэн нийслэл хот, хөдөө орон нутагт захиалгын бараа бүтээгдэхүүнийг хүргэх үйлчилгээ үзүүлнэ.</p>
                        <p className='mt-3'>8.2 Хүргэлтийн үйлчилгээ авах эсэхийг хэрэглэгч захиалга өгөх үедээ мэдэгдэх бөгөөд хүргүүлэх газрын байрлалаас шалтгаалан хүргэлтийн үнэ тогтоож, каргоны төлбөр дээр нэмж тооцогдоно.</p>
                        <p className='mt-3'>8.3 Улаанбаатар хотод хүргэлтийн үйлчилгээг бүсчилсэн үнийн тарифаар гүйцэтгэнэ.</p>
                        <p className='mt-3'>8.4 Орон нутгийн хүргэлтийг хот хоорондын тээврийн үйлчилгээ үзүүлэгч автобусны цагийн хуваарьт тааруулж хүргэлт хийх боломжгүй бөгөөд захиалагчтай 
                        тохиролцон, тээшинд үлдээж болно. Орон нутгийн унаанд тавих өгөх төлбөр нь хот доторх тээврийн үнэтэй ижил байна.</p>
                        <p className='mt-3'>8.5 Хүргэлттэй холбоотой асуудлыг хүргэлт хариуцсан жолоочтой холбогдож шийдвэрлэнэ. Хүргэлт захиалсан боловч бараагаа аваагүй, утсаа аваагүй 
                        гэх мэт шалтгаанаар бараа буцаж ирсэн тохиолдолд хүргэлтийн төлбөр давхар тооцогдоно</p>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem className='border rounded-2xl bg-[#4343af] mt-2'>
                    <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign='left'>
                            ХАРИУЦЛАГА
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} mx={4} className='text-sm text-gray-300'>
                        <p className='mt-1'>9.1 Хэрэглэгчийн сонгосон захиалгын бүтээгдэхүүнүүд нь КАРГО-тай хамааралгүй БНХАУ-ын дэлгүүр, нийлүүлэгчийн бараа бүтээгдэхүүн тул тэдгээрийн чанар бусад үзүүлэлтүүдэд КАРГО хариуцлага хүлээхгүй.</p>
                        <p className='mt-3'>9.2 Дэлгүүрийн найдвартай байдал, бараа бүтээгдэхүүний сонголт, чанартай холбоотой асуудлыг хэрэглэгч бүрэн хариуцна.</p>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem className='border rounded-2xl bg-[#4343af] mt-2'>
                    <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign='left'>
                            БУСАД
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} mx={4} className='text-sm text-gray-300'>
                        <p className='mt-1'>10.1 Захиалгын бараа бүтээгдэхүүн дууссан тохиолдолд хэрэглэгчид эргэн мэдэгдэх ба хэрэглэгч захиалгыг өөр бүтээгдэхүүнээр солих эсвэл тухайн барааны захиалгыг цуцалж болно.</p>
                        <p className='mt-3'>10.2 Захиалгыг цуцалсан тохиолдолд дууссан бараа бүтээгдэхүүний үнийг хасан захиалгын нийт үнэ бодогдоно.</p>
                        <p className='mt-3'>10.3 Тухайн нөхцөлд дууссан боловч тодорхой хугацааны дараа тухайн бараа онлайн дэлгүүрт ирэхийг мэдэгдсэн тохиолдолд худалдан авалтыг үргэлжлүүлэх эсэхийг хэрэглэгчийн хүсэлтээр шийдвэрлэнэ.</p>
                        <p className='mt-3'>10.4 Хэрэглэгч өөрийн захиалсан бараагаа 14-21 хоног тутамд шалгуулах эрхтэй ба Карго захиалгыг тухайн бүрт нь шалгаад буцаалт хийх, нөхөн гаргуулах арга хэмжээг авна.</p>
                        <p className='mt-3'>10.5 Буцаалт хийх , нөхөн төлөх хугацаа 2 сараас дээш хэтэрвэл БНХАУ-ын дэлгүүр, нийлүүлэгчид асуудлыг шийдвэрлэхээс эрс татгалздаг бөгөөд энэ тохиолдолд Карго нөхөн төлбөр олгох үүрэггүй.</p>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </div>
    </div>
  )
}

export default Service