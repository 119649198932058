import React, { useContext } from 'react'
import { BiSolidBox, BiSolidHome, BiSolidNote, BiSolidUser } from "react-icons/bi";
import {Link, useLocation, useNavigate} from 'react-router-dom';
import { AuthContext } from '../../context/auth';

const SNav = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();
  const {currentUser} = useContext(AuthContext);

  function nav(navs){
    navigate(navs)
  }

  return (
    // FACB41
    <div>
      <div className='flex justify-between rounded-t-2xl shadow-lg shadow-t-white font-Roboto fixed bottom-0 xs:w-full md:w-[700px] bg-[#121285] xs:text-[10px] md:text-sm text-center'>
        {/* <div className='flex justify-between w-full xs:mx-4 md:mx-20 xs:text-[12px] md:text-sm'> */}

          <div onClick={() => nav('/')} className={pathname === '/' ? 'text-white font-bold hover:text-opacity-100 hover:font-bold w-full  cursor-pointer p-2 bg-[#3b3ba3] rounded-tl-2xl'
          :'text-white opacity-70 hover:text-opacity-100 hover:font-bold cursor-pointer font-bold w-full p-2'}>
            <div className='flex justify-center'>
              <div className='xs:hidden md:block'>
                <BiSolidHome size={28}/>
              </div>
              <div className='xs:block md:hidden'>
                <BiSolidHome size={20}/>
              </div>
            </div>
            <h1 className=''>Нүүр</h1>
          </div>

          <div onClick={() => nav('/address')} className={pathname === '/address' ? 'text-white font-bold hover:text-opacity-100 cursor-pointer hover:font-bold w-full p-2 bg-[#3b3ba3]'
          :'text-white opacity-70 hover:opacity-100 hover:font-bold cursor-pointer font-bold w-full p-2'}>
            <div className='flex justify-center'>
              <div className='xs:hidden md:block'>
                <BiSolidNote size={28}/>
              </div>
              <div className='xs:block md:hidden'>
                <BiSolidNote size={20}/>
              </div>
            </div>
            <h1 className=''>Хаяг холбох</h1>
          </div>

          <div onClick={() => nav('/product-check')} className={pathname === '/product-check' ? 'text-white font-bold hover:text-opacity-100 cursor-pointer hover:font-bold w-full p-2 bg-[#3b3ba3]'
          :'text-white opacity-70 hover:opacity-100 hover:font-bold cursor-pointer font-bold w-full p-2'}>
            <div className='flex justify-center'>
              <div className='xs:hidden md:block'>
                <BiSolidBox size={28}/>
              </div>
              <div className='xs:block md:hidden'>
                <BiSolidBox size={20}/>
              </div>
            </div>
            <h1 className=''>Бараа шалгах</h1>
          </div>

          {
            currentUser ?
            <div onClick={() => nav('/profile')} className={pathname === '/profile' ? 'text-white font-bold hover:text-opacity-100 cursor-pointer hover:font-bold w-full p-2 bg-[#3b3ba3] rounded-tr-2xl'
            :'text-white opacity-70 hover:opacity-100 hover:font-bold cursor-pointer font-bold w-full p-2'}>
              <div className='flex justify-center'>
                <div className='xs:hidden md:block'>
                  <BiSolidUser size={28}/>
                </div>
                <div className='xs:block md:hidden'>
                  <BiSolidUser size={20}/>
                </div>
              </div>
              <h1 className=''>Profile</h1>
            </div>
          :
          <div onClick={() => nav('/login')} className={pathname === '/login' ? 'text-white font-bold hover:text-opacity-100 cursor-pointer hover:font-bold w-full p-2 bg-[#3b3ba3] rounded-tr-2xl'
            :'text-white opacity-70 hover:opacity-100 hover:font-bold cursor-pointer font-bold w-full p-2'}>
              <div className='flex justify-center'>
                <div className='xs:hidden md:block'>
                  <BiSolidUser size={28}/>
                </div>
                <div className='xs:block md:hidden'>
                  <BiSolidUser size={20}/>
                </div>
              </div>
              <h1 className=''>Нэвтрэх</h1>
            </div>
          }

        {/* </div> */}
      </div>
    </div>
  )
}

export default SNav