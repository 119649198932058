import React, { useEffect, useState } from 'react'
import { useParams} from 'react-router-dom';
import { GET } from '../../../utils/requests';
import { lesson_uri } from '../../../uri';
import {AspectRatio} from "@chakra-ui/react";

const LessonSlug = () => {
  const params = useParams();
  const [data, setData] = useState({});

  useEffect(() => {
    Get();
  }, []);

  const Get = async () => {
    const res = await GET(lesson_uri+"/detail/"+`${params.id}`);
    console.log(res.data)
    setData(res.data)
  }
  


  return (
    <div className='text-white mt-8'>
      <h1 className='text-xl font-bold'>{data.name}</h1>
      <div className='mt-4'>
        <AspectRatio maxW='full' ratio={1}>
          <iframe
            src={data.link}
            allowFullScreen
          />
        </AspectRatio>
      </div>
    </div>
  )
}

export default LessonSlug