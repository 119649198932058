import React, { useEffect, useState } from 'react'
import LessonCard from './card'
import { GET } from '../../../utils/requests';
import { lesson_uri } from '../../../uri';
import {Skeleton} from "@chakra-ui/react";

const Lesson = () => {
    const [web, setWeb] = useState([]);
    const [tabao, setTabao] = useState([]);
    const [load, setLoad] = useState(false);

    const Get  = async()=>{
        setLoad(true);
        try{
            const res = await GET(lesson_uri+'/user');
            setTabao(res.data.tabao);
            setWeb(res.data.website);
            setLoad(false);
        }catch(err){
            setLoad(false);
            console.log(err);
        }
    }

    useEffect(() => {
        Get();
    }, []);

    
    
  return (
    <div className='text-white font-Roboto'>
        <div className='mt-10'>
            <h1 className='font-bold text-md uppercase text-xl'>Заавар хичээлүүд</h1>
            <p className='text-sm text-gray-300'>Helps, tutorial</p>
        </div>
        <div className='mt-4'>
            <div className='flex items-center gap-2'>
                <img className='rounded-[16px] h-12' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMstl029WKE1BjWdWDMuBh0JARBb63n2oMlcu9upNPoA&s'/>
                <h1 className='text-sm text-gray-300'>Taobao аппликейшнтэй холбоотой заавар хичээлүүд</h1>
            </div>
            <div className='grid grid-cols-3 mt-2 gap-2'>
                {
                    load?
                    <>
                        <Skeleton className='h-20'/>
                        <Skeleton className='h-20'/>
                        <Skeleton className='h-20'/>
                    </>
                    :
                    <>
                        {
                            tabao.map((it, index) => {
                                return(
                                    <div>
                                        <LessonCard key={index} data={it}/>
                                    </div>
                                )
                            })
                        }
                    </>
                }
            </div>
        </div>
        <div className='mt-4'>
            Сайн карго заавар хичээлүүд
            <div className='grid grid-cols-3 mt-2 gap-2'>
                {
                    load?
                    <>
                        <Skeleton className='h-20'/>
                        <Skeleton className='h-20'/>
                        <Skeleton className='h-20'/>
                    </>
                    :
                    <>
                        {
                            web.map((it, index) => {
                                return(
                                    <div>
                                        <LessonCard key={index} data={it}/>
                                    </div>
                                )
                            })
                        }
                    </>
                }
            </div>
        </div>
    </div>
  )
}

export default Lesson